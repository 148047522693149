export const socialIconList = [
  {
    name: 'linkedin-in',
    prefix: 'fab',
    link: 'https://www.linkedin.com/in/danielduartego/',
  },
  {
    name: 'github',
    prefix: 'fab',
    link: 'https://github.com/danielduartego',
  },
  {
    name: 'bitbucket',
    prefix: 'fab',
    link: 'https://bitbucket.org/danielduartego/',
  },
  {
    name: 'twitter',
    prefix: 'fab',
    link: 'https://twitter.com/danielduartego',
  },
  {
    name: 'spotify',
    prefix: 'fab',
    link: 'https://open.spotify.com/user/12155799350?si=9faf2bc6b3b44156',
  },
  {
    name: 'instagram',
    prefix: 'fab',
    link: 'https://www.instagram.com/danielduartego/',
  },
  // Add further social links with the icon of choice and link here
  // check https://fontawesome.com/icons?d=gallery&s=brands for other brand icons
];
